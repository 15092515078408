import {getItemFromStorage} from "./storage";

const getUser = () => {
  const user = getItemFromStorage("userInfo");
  return JSON.parse(user);
}

const isSuperUser = () => {
  const user = getItemFromStorage("userInfo");
  return JSON.parse(user)?.is_superuser;
}

export {isSuperUser, getUser};