import { baseSiteEndPoint, buildHeaders, getSellerID, urlFilters } from "./common";
import { authorizedFetch } from "./login";

export const apiReceivablesList = async (filters) => {
  try {
    const response = await authorizedFetch(urlFilters(`${baseSiteEndPoint}/unified/accounts/sellers/${getSellerID()}/receivables/`, filters), {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();

    return data;
  } catch (error) {
    throw error;
  }
};

export const apiReceivableDetail = async (id) => {

  try {
    const response = await authorizedFetch(`${baseSiteEndPoint}/unified/accounts/sellers/${getSellerID()}/receivables/${id}`, {
      method: "GET",
      headers: buildHeaders(),
    });
    const data = await response.json();
    return data;
  } catch (error) {
    throw error;
  }
};
