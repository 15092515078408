import { DownOutlined, FileDoneOutlined, InfoCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  DatePicker,
  Divider,
  Dropdown,
  Empty,
  Form,
  Input,
  Layout,
  Modal,
  Result,
  Select,
  Skeleton,
  Space,
  Table,
  notification,
  theme
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiTransactionDownload, apiTransactionsList } from "../../../api/transactions";
import { useIsMobileHook } from "../../../utils/WindowHooks";
import { formatDate, formatPrice } from "../../../utils/format";
import SellerDropdown from "../../common/SellerDropdown/SellerDropdown";
import "./SalesListing.scss";

const { Content } = Layout;
const { RangePicker } = DatePicker;
const { Option } = Select;

const SalesListing = () => {
  const [dataSales, setDataSale] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const isMobile = useIsMobileHook(1600);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSales?.results);
  const [statusFilter, setStatusFilter] = useState([]);
  const [gatewayFilter, setGatewayFilter] = useState("");
  const [descriptorFilter, setDescriptorFilter] = useState("");
  const [paymentTypeFilter, setPaymentTypeFilter] = useState("");
  const [dateRange, setDateRange] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const [searchAvanced, setSearchAvanced] = useState(false);
  const [exportFormat, setExportFormat] = useState('csv')

  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      fetchData({
        page: pagination.current,
        search: searchText,
        status: statusFilter.length > 0 ? statusFilter : undefined,
        gateway_authorizer: gatewayFilter || undefined,
        statement_descriptor: descriptorFilter || undefined,
        payment_type: paymentTypeFilter || undefined,
        created_at_gte: dateRange[0]?.format("YYYY-MM-DD") || undefined,
        created_at_lte: dateRange[1]?.format("YYYY-MM-DD") || undefined,
      });
    }, 1500)

    return () => clearTimeout(delayDebounceFn)
  }, [searchText])

  const handleSearch = (value) => {
    fetchData({
      page: pagination.current,
      search: searchText,
      status: statusFilter.length > 0 ? statusFilter : undefined,
      gateway_authorizer: gatewayFilter || undefined,
      statement_descriptor: descriptorFilter || undefined,
      payment_type: paymentTypeFilter || undefined,
      created_at_gte: dateRange[0]?.format("YYYY-MM-DD") || undefined,
      created_at_lte: dateRange[1]?.format("YYYY-MM-DD") || undefined,
    });
  };

  const fetchData = async (params) => {
    setIsFetching(true);
    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined && value !== null && value !== '')
      );
      const json = await apiTransactionsList(filteredParams);
      setDataSale(json);
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: params.page,
      }));
    } catch (error) {
      setActiveError(true);
      setErrorStatus(error.status);
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao buscar as vendas.",
        placement: "topRight",
      });
    } finally {
      setIsFetching(false);
    }
  };

  const handleFilterChange = () => {
    const filters = {};

    if (searchText) filters.search = searchText;
    if (statusFilter.length > 0) filters.status = statusFilter;
    if (gatewayFilter) filters.gateway_authorizer = gatewayFilter;
    if (descriptorFilter) filters.statement_descriptor = descriptorFilter;
    if (paymentTypeFilter) filters.payment_type = paymentTypeFilter;
    if (dateRange && dateRange.length === 2) {
      filters.created_at_gte = dateRange[0]?.format("YYYY-MM-DD");
      filters.created_at_lte = dateRange[1]?.format("YYYY-MM-DD");
    }

    fetchData({
      page: pagination.current,
      ...filters,
    });
  };

  const onChange = (pagination) => {
    fetchData({
      page: pagination.current,
      pageSize: pagination.pageSize,
      search: searchText || undefined,
      status: statusFilter.length > 0 ? statusFilter : undefined,
      gateway_authorizer: gatewayFilter || undefined,
      statement_descriptor: descriptorFilter || undefined,
      payment_type: paymentTypeFilter || undefined,
      created_at_gte: dateRange[0]?.format("YYYY-MM-DD") || undefined,
      created_at_lte: dateRange[1]?.format("YYYY-MM-DD") || undefined,
    });
  };

  useEffect(() => {
    fetchData({
      page: pagination.current,
      pageSize: pagination.pageSize,
      search: searchText || undefined,
      status: (Array.isArray(statusFilter) && statusFilter.length > 0) ? statusFilter : undefined,
      gateway_authorizer: gatewayFilter || undefined,
      statement_descriptor: descriptorFilter || undefined,
      payment_type: paymentTypeFilter || undefined,
      created_at_gte: dateRange[0]?.format("YYYY-MM-DD") || undefined,
      created_at_lte: dateRange[1]?.format("YYYY-MM-DD") || undefined,
    });
  }, [statusFilter, gatewayFilter, descriptorFilter, paymentTypeFilter, dateRange]);


  const statusOptions = [
    { value: 'succeeded', label: 'Confirmada' },
    { value: 'failed', label: 'Não autorizada' },
    { value: 'canceled', label: 'Cancelada' },
    { value: 'pre_authorized', label: 'Pré Autorizada' },
    { value: 'reversed', label: 'Revertida' },
    { value: 'refunded', label: 'Estornado' },
    { value: 'partial_refunded', label: 'Estornado Parcialmente' },
    { value: 'pending', label: 'Pendente' },
    { value: 'new', label: 'Novo' },
    { value: 'created', label: 'Recém Criado' },
    { value: 'dispute', label: 'Em Disputa' },
    { value: 'charged_back', label: 'Estornado' }
  ];

  const statusMap = {
    succeeded: <span className="badge badge-success">Confirmada</span>,
    failed: <span className="badge badge-error">Não autorizada</span>,
    canceled: <span className="badge badge-error">Cancelada</span>,
    pre_authorized: <span className="badge yellow">Pré Autorizada</span>,
    reversed: <span className="badge yellow">Revertida</span>,
    refunded: <span className="badge blue">Estornado</span>,
    partial_refunded: <span className="badge blue">Estornado Parcialmente</span>,
    pending: <span className="badge orange">Pendente</span>,
    new: <span className="badge blue">Nova</span>,
    created: <span className="badge blue">Nova</span>,
    dispute: <span className="badge badge-error">Em Disputa</span>,
    charged_back: <span className="badge blue">Estornado</span>
  };

  const renderStatus = (status) => {
    return statusMap[status] || <span className="badge black">Indefinido</span>;
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "created_at",
      key: "created_at",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatDate(confirmed) }}
        />
      ),
      responsive: ["sm"],
    },
    {
      title: "CE",
      dataIndex: "statement_descriptor",
      key: "statement_descriptor",
      sorter: (a, b) => {
        const statusOrder = {
          1: 1,
          0: 2,
        };
        return statusOrder[a.confirmed] - statusOrder[b.confirmed];
      },
    },
    {
      title: "Tipo",
      dataIndex: "payment_type",
      key: "payment_type",
      render: (paymentType) => {
        switch (paymentType) {
          case "credit":
            return "Crédito";
          case "debit":
            return "Débito";
          case "pix":
            return "Pix";
          case "boleto":
            return "Boleto";
          default:
            return "Desconhecido";
        }
      },
      responsive: ["sm"],
    },
    {
      title: "Bandeira",
      dataIndex: "gateway_authorizer",
      key: "gateway_authorizer",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: confirmed }}
        />
      ),
      responsive: ["xxl"],
    },
    {
      title: "Valor Bruto",
      dataIndex: "amount",
      sorter: (a, b) => parseFloat(a.amount) - parseFloat(b.amount),
      key: "amount",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
      sorter: (a, b) => {
        const statusOrder = {
          succeeded: 1,
          failed: 2,
        };
        return statusOrder[a.status] - statusOrder[b.status];
      },
      responsive: ["lg"],
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link to={`/transacao/${confirme.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      ),
      key: "",
      responsive: ["lg"],
    },
  ];

  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
        Data:{" "}
        <p
          dangerouslySetInnerHTML={{ __html: formatDate(record.created_at) }}
        />
      </span>
      <span>
        Tipo:{" "}
        <p
          dangerouslySetInnerHTML={{
            __html: (() => {
              switch (record.payment_type) {
                case "credit":
                  return "Crédito";
                case "debit":
                  return "Débito";
                case "pix":
                  return "Pix";
                case "boleto":
                  return "Boleto";
                default:
                  return "Desconhecido";
              }
            })(),
          }}
        />
      </span>
      <span>
        Bandeira:{" "}
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: record.gateway_authorizer }}
        />
      </span>
      <span>
        Valor bruto:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatPrice(record.amount) }} />
      </span>
      <span>
        Status:{" "}
        {renderStatus(record.status)}
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link to={`/transacao/${record.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
    </div>
  );
  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  const showLimitationWarning = () => {
    Modal.warning({
      title: 'Limitação de Exportação',
      content: 'As exportações são limitadas a até 10.000 resultados. Se a sua pesquisa retornar mais de 10.000 resultados, somente os primeiros 10.000 serão exportados.',
      onOk() { },
    });
  };

  const handleDownload = async (format) => {
    showLimitationWarning();
    const filters = {
      search: searchText || undefined,
      status: statusFilter || undefined,
      gateway_authorizer: gatewayFilter || undefined,
      statement_descriptor: descriptorFilter || undefined,
      payment_type: paymentTypeFilter || undefined,
      created_at_gte: dateRange[0]?.format("YYYY-MM-DD") || undefined,
      created_at_lte: dateRange[1]?.format("YYYY-MM-DD") || undefined,
    };

    const filteredParams = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== undefined)
    );

    try {
      const blob = await apiTransactionDownload(format, filteredParams);
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `transacoes.${format === 'csv' ? 'csv' : 'xlsx'}`);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Failed to download file:', error);
    }
  };

  const items = [
    {
      label: 'CSV',
      key: 'csv',
      icon: <FileDoneOutlined />,
    },
    {
      label: 'EXCEL',
      key: 'excel',
      icon: <FileDoneOutlined />,
    },
  ];

  const handleMenuClick = (e) => {
    handleDownload(e.key);
  };

  const menuProps = {
    items,
    onClick: handleMenuClick,
  };

  return (
    <div className="SalesListing">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <h2 className="title-page">Listagem de vendas</h2>
            <SellerDropdown />
          </div>
          <Divider />
          <Breadcrumb.Item>Minhas vendas</Breadcrumb.Item>
          <Breadcrumb.Item>Listagem de vendas</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className="subtitle-page">Listagem de vendas</h3>
          <Divider />
          {searchAvanced && <Form
            layout="inline"
            display='flex'
            style={{ marginBottom: 16, gap: '16px' }}
            onFinish={handleFilterChange}
          >
            <Form.Item label="Status">
              <Select
                placeholder="Selecione o Status"
                onChange={(values) => setStatusFilter(values)}
                style={{ width: 200 }}
                allowClear
              >
                {statusOptions.map((status) => (
                  <Option key={status.value} value={status.value}>
                    {status.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label="Gateway">
              <Input
                placeholder="Digite o Gateway"
                onChange={(e) => setGatewayFilter(e.target.value)}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item label="Nome na fatura">
              <Input
                placeholder="Digite o Nome na fatura"
                onChange={(e) => setDescriptorFilter(e.target.value)}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item label="Tipo de pagamento">
              <Select
                placeholder="Selecione o Tipo de pagamento"
                allowClear
                onChange={(value) => setPaymentTypeFilter(value)}
                style={{ width: 200 }}
              >
                <Option value="credit">Crédito</Option>
                <Option value="debit">Débito</Option>
                <Option value="pix">Pix</Option>
                <Option value="boleto">Boleto</Option>
              </Select>
            </Form.Item>
            <Form.Item label="Transações Após">
              <DatePicker
                onChange={(dates) => setDateRange([dates, dateRange[1]])}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item label="Transações Antes">
              <DatePicker
                onChange={(dates) => setDateRange([dateRange[0], dates])}
                style={{ width: 200 }}
              />
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit">
                Aplicar Filtros
              </Button>
            </Form.Item>
          </Form>}
          {!searchAvanced && <Button style={{ marginRight: 16 }} onClick={() => setSearchAvanced(true)}>Filtros avançados</Button>}
          <Input.Search
            placeholder="Pesquisar"
            allowClear
            enterButton
            onSearch={handleSearch}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 16, maxWidth: '200px' }}
          />
          {isFetching ? (
            <Skeleton active />
          ) : activeError ? (
            renderError(errorStatus)
          ) : (
            <Table
              columns={columns}
              locale={locale}
              expandable={{
                expandedRowRender: isMobile ? expandedRowRender : undefined,
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => handleRowClick(record),
                responsive: ["xl"],
              }}
              dataSource={
                filteredData &&
                filteredData.map((item) => ({ ...item, key: item.id }))
              }
              pagination={{
                current: pagination.current,
                total: pagination.total,
                pageSize: pagination.pageSize,
                showSizeChanger: false,
              }}
              bordered
              onChange={onChange}
            />
          )}

          <div className="download-section">
            <Dropdown menu={menuProps} >
              <Button>
                <Space>
                  Baixar Relatório
                  <DownOutlined />
                </Space>
              </Button>
            </Dropdown>
          </div>
        </Content>
      </Layout>
    </div >
  );
};

export default SalesListing;