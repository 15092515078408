import {
  Breadcrumb,
  Button,
  Card,
  Checkbox,
  DatePicker,
  Divider,
  Form,
  Input,
  Layout,
  Select,
  message,
  notification,
  theme, Row, Col, InputNumber, Tooltip,
} from "antd";
import { Content } from "antd/es/layout/layout";
import { Option } from "antd/es/mentions";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiCheckoutCreate, apiPaymentCreate } from "../../../api/payment";
import InputMoney from "../../common/InputMoney/InputMoney";
import "./PaymentCreate.scss";
import {QuestionCircleOutlined} from "@ant-design/icons";
const PaymentCreate = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const navigate = useNavigate();
  const [name, setName] = useState();
  const [boleto, setBoleto] = useState();
  const [credit, setCredit] = useState();
  const [pix, setPix] = useState();
  const [maxInstallments, setMaxInstallments] = useState();
  const [maxInstallmentsTax, setMaxInstallmentsTax] = useState(0);
  const [description, setDescription] = useState();
  const [reference, setReference] = useState();
  const [expirateDate, setExpirateDate] = useState();
  const [date, setDate] = useState();
  const [amount, setAmount] = useState({ price: 0 });

  // boleto settings
  const [boletoExpirationMode, setBoletoExpirationMode] = useState('STANDARD');
  const [boletoExpirationDate, setBoletoExpirationDate] = useState();
  const [boletoPaymentLimitDate, setBoletoPaymentLimitDate] = useState();
  const [boletoLateFeeMode, setBoletoLateFeeMode] = useState('NONE');
  const [boletoLateFeeValue, setBoletoLateFeeValue] = useState(1);
  const [boletoLateFeeStartDate, setBoletoLateFeeStartDate] = useState();
  const [boletoInterestMode, setBoletoInterestMode] = useState('NONE');
  const [boletoInterestValue, setBoletoInterestValue] = useState(1);
  const [boletoInterestStartDate, setBoletoInterestStartDate] = useState();

  const handleSubmit = async () => {
    try {
      if (amount.price === 0) {
        notification.error({
          message: "Erro",
          description: "Preencha o campo de valor!",
          placement: "topRight",
        });
        return;
      }

      const jsonData = {
        allow_boleto: boleto || false,
        allow_credit_card: credit || false,
        allow_pix: pix || false,
        boleto_max_installments: 1,
        max_installments: maxInstallments,
        max_installments_no_tax: maxInstallmentsTax,
        name,
      };
      if (boletoExpirationMode === "CUSTOM") {
        jsonData.boleto_expiration_date = boletoExpirationDate.toISOString().split('T')[0];
        jsonData.boleto_payment_limit_date = boletoPaymentLimitDate.toISOString().split('T')[0];

        jsonData.boleto_late_fee_mode = boletoLateFeeMode;
        if (boletoLateFeeMode !== "NONE") {
          if (boletoLateFeeMode === "PERCENTAGE") {
            jsonData.boleto_late_fee_value = parseInt(boletoLateFeeValue);
          } else {
            jsonData.boleto_late_fee_value = parseInt(parseFloat(boletoLateFeeValue) * 100);
          }
          jsonData.boleto_late_fee_start_date = boletoLateFeeStartDate.toISOString().split('T')[0];
        }

        jsonData.boleto_interest_mode = boletoInterestMode;
        if (boletoInterestMode !== "NONE") {
          if (boletoInterestMode === "DAILY_PERCENTAGE" || boletoInterestMode === "MONTHLY_PERCENTAGE") {
            jsonData.boleto_interest_value = parseInt(boletoInterestValue);
          } else {
            jsonData.boleto_interest_value = parseInt(parseFloat(boletoInterestValue) * 100);
          }
          jsonData.boleto_interest_start_date = boletoInterestStartDate.toISOString().split('T')[0];
        }
      } else {
        jsonData.boleto_expiration_date = null;
        jsonData.boleto_payment_limit_date = null;
        jsonData.boleto_late_fee_mode = "NONE";
        jsonData.boleto_late_fee_value = 1;
        jsonData.boleto_late_fee_start_date = null;
        jsonData.boleto_interest_mode = "NONE";
        jsonData.boleto_interest_value = 1;
        jsonData.boleto_interest_start_date = null;
      }

      const paymentPlanResponse = await apiPaymentCreate(jsonData)
        .then((response) => response)
        .catch((error) => {
          throw error;
        });

      const checkoutPayload = {
        amount: amount.price,
        description,
        name,
        expiration_date: date,
        reference: !reference
          ? name
              .toLowerCase()
              .replace(/\s+/g, "-")
              .replace(/[^\w-]+/g, "")
              .slice(0, 100)
          : reference,
        payment_plan: paymentPlanResponse.id,
      };

      await apiCheckoutCreate(checkoutPayload).catch((error) => {
        throw error;
      });

      notification.success({
        message: "Sucesso",
        description:
          "Link de pagamento criado e checkout realizado com sucesso.",
        placement: "topRight",
      });

      navigate(`/pagamentos`);
    } catch (error) {
      if (error.status === 400) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao criar o link de pagamento! Preencha todos os campos de informações do plano de pagamento.",
          placement: "topRight",
        });
      } else if (error.status === 500) {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro no servidor ao criar o link de pagamento. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      } else {
        notification.error({
          message: "Erro",
          description:
            "Ocorreu um erro ao processar a requisição. Por favor, tente novamente mais tarde.",
          placement: "topRight",
        });
      }
    }
  };

  const handlePixToggle = () => {
    setPix((prevPix) => !prevPix);
  };

  const handleCrediToggle = () => {
    setCredit((prevPix) => !prevPix);
  };

  const handleBoletoToggle = () => {
    setBoleto((prevPix) => !prevPix);
  };

  const handleDateChange = (date, dateString) => {
    setExpirateDate(date);
    setDate(dateString);
  };

  const onChangeInput = (event, nameObject, isNumber) => {
    setAmount({
      [nameObject]: isNumber ? Number(event.target.value) : event.target.value,
    });
  };
  const [form] = Form.useForm();
  const onFinish = () => {
    form
      .validateFields()
      .then((values) => {
        if (!(values.name && values.description && values.expirateDate)) {
          message.error("Preencha todos os campos obrigatórios.");
          return;
        }
        if(boletoExpirationMode === "CUSTOM") {
          if(!boletoExpirationDate || !boletoPaymentLimitDate) {
            message.error("Data de expiração e data limite de pagamento são obrigatórias para boletos personalizados.");
            return;
          }
          if (boletoExpirationDate > boletoPaymentLimitDate) {
            message.error("Data de expiração deve ser anterior à data limite de pagamento.");
            return;
          }
          if (boletoLateFeeMode !== "NONE") {
            if (!boletoLateFeeStartDate) {
              message.error("Data de início da multa é obrigatória.");
              return;
            }
            if (boletoLateFeeStartDate < boletoExpirationDate) {
              message.error("Data de início da multa deve ser posterior à data de expiração.");
              return;
            }
            if (boletoLateFeeStartDate > boletoPaymentLimitDate) {
              message.error("Data de início da multa deve ser anterior à data limite de pagamento.");
              return;
            }
            if (!boletoLateFeeValue) {
              message.error("O valor do juros é obrigatório.");
              return;
            }
            if (boletoLateFeeMode === "PERCENTAGE") {
              if (boletoLateFeeValue > 99) {
                message.error("O valor da multa em porcentagem não pode ser maior que 99%.");
                return;
              }
            }
          }
          if (boletoInterestMode !== "NONE") {
            if (!boletoInterestStartDate) {
              message.error("Data de início do juros é obrigatória.");
              return;
            }
            if (boletoInterestStartDate < boletoExpirationDate) {
              message.error("Data de início do juros deve ser posterior à data de expiração.");
              return;
            }
            if (boletoInterestStartDate > boletoPaymentLimitDate) {
              message.error("Data de início do juros deve ser anterior à data limite de pagamento.");
              return;
            }
            if (!boletoInterestValue) {
              message.error("O valor do juros é obrigatório.");
              return;
            }

            if (boletoInterestMode === "DAILY_PERCENTAGE") {
              if (boletoInterestValue > 3) {
                message.error("O valor do juros diário não pode ser maior que 3%.");
                return;
              }
              if ((boletoInterestValue / 100.0) * amount.price < 0.3) {
                message.error("O calculo da porcentagem de juros não pode resultar em um valor menor que R$0,30.");
                return;
              }
            }
            if (boletoInterestMode === "MONTHLY_PERCENTAGE") {
              if (boletoInterestValue > 99) {
                message.error("O valor do juros mensal não pode ser maior que 99%.");
                return;
              }
              if ((boletoInterestValue / 100.0) * amount.price < 0.3) {
                message.error("O calculo da porcentagem de juros não pode resultar em um valor menor que R$0,30.");
                return;
              }
            }
          }
        }

        handleSubmit();
      })
      .catch(() => {
        message.error("Preencha todos os campos obrigatórios.");
      });
  };

  return (
    <div className="PaymentCreate">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <h2 className="title-page">Criar Pagamento</h2>
          </div>
          <Divider />
          <Breadcrumb.Item>Pagamento</Breadcrumb.Item>
          <Breadcrumb.Item>Criar Pagamento</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">1. Link de Pagamento</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-left">
              <Form form={form}>
                <Form.Item
                  label="Nome do Link"
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira o nome do link.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </Form.Item>
                <Form.Item
                  label="Descrição"
                  name="description"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, insira a descrição.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setDescription(e.target.value)}
                    value={description}
                  />
                </Form.Item>
                <Form.Item
                  label="Valor"
                  rules={[
                    { required: true, message: "Por favor, insira o valor." },
                  ]}
                >
                  <InputMoney
                    onChange={(e) => onChangeInput(e, "price", true)}
                    value={amount?.price || "0,00"}
                    placeholder="R$ 0,00"
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="container-right">
              <Form form={form}>
                <Form.Item
                  label="Referência"
                  name="reference"
                  rules={[
                    {
                      required: false,
                      message: "Por favor, selecione a data de vencimento.",
                    },
                  ]}
                >
                  <Input
                    onChange={(e) => setReference(e.target.value)}
                    value={reference}
                  />
                </Form.Item>
                <Form.Item
                  label="Data de Vencimento"
                  name="expirateDate"
                  rules={[
                    {
                      required: true,
                      message: "Por favor, selecione a data de vencimento.",
                    },
                  ]}
                >
                  <DatePicker
                    value={expirateDate}
                    format="YYYY-MM-DD"
                    onChange={handleDateChange}
                  />
                </Form.Item>
              </Form>
            </div>
          </div>
        </Content>

        <Content
          style={{
            padding: 24,
            margin: "30px 0 0 0",
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <div className="container-subtitle">
            <h3 className="subtitle-page">2. Plano de Pagamento</h3>
          </div>
          <Divider />
          <div className="container-inputs">
            <div className="container-left">
              <Card>
                <p className="title">Boleto</p>
                <Divider />
                <Checkbox checked={boleto} onChange={handleBoletoToggle}>
                  Permitir Boleto
                </Checkbox>
                {boleto && (
                  <>
                    <div>
                      <p>Tipo de Vencimento</p>
                      <Select
                        value={boletoExpirationMode}
                        onChange={(e) => setBoletoExpirationMode(e)}
                      >
                        <Option value="STANDARD">
                          Padrão (3 dias após a criação)
                        </Option>
                        <Option value="CUSTOM">
                          Avançado (Data fixa, com juros e descontos)
                        </Option>
                      </Select>
                    </div>
                    {boletoExpirationMode === "CUSTOM" && (
                    <>
                      <Row gutter={16}>
                        <Col span={10}>
                          <p>
                            Data de Vencimento
                            <Tooltip title={"Data visível no boleto"}><QuestionCircleOutlined className={"helpIcon"}/></Tooltip>
                          </p>
                          <DatePicker
                            value={boletoExpirationDate}
                            onChange={(e) => setBoletoExpirationDate(e)}
                            format="YYYY-MM-DD"
                          />
                        </Col>
                        <Col span={10}>
                          <p>
                            Data Limite de Pagamento
                            <Tooltip title={"Após essa data, os bancos rejeitarão o pagamento"}><QuestionCircleOutlined className={"helpIcon"}/></Tooltip>
                          </p>
                          <DatePicker
                            value={boletoPaymentLimitDate}
                            onChange={(e) => setBoletoPaymentLimitDate(e)}
                            format="YYYY-MM-DD"
                          />
                        </Col>
                      </Row>
                      <Row className={"boleto-interest-settings"}>
                        <h3>Configurações avançadas</h3>
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <p>Haverá Multa?</p>
                          <Select
                            value={boletoLateFeeMode}
                            onChange={(e) => setBoletoLateFeeMode(e)}
                          >
                            <Option value="NONE">
                              Não
                            </Option>
                            <Option value="FIXED">
                              Sim, Valor Fixo
                            </Option>
                            <Option value="PERCENTAGE">
                              Sim, Porcentagem
                            </Option>
                          </Select>
                        </Col>
                        {boletoLateFeeMode !== "NONE" && (
                          <>
                            <Col span={8}>
                              <p>De Quanto?</p>
                              {boletoLateFeeMode === "FIXED" && <>
                                <InputMoney
                                  value={boletoLateFeeValue}
                                  onChange={(e) => setBoletoLateFeeValue(e.target.value)}
                                  addonBefore={"R$"} min={0.01} />
                              </>}
                              {boletoLateFeeMode === "PERCENTAGE" && <>
                                <InputNumber
                                  value={boletoLateFeeValue}
                                  onChange={(e) => setBoletoLateFeeValue(e)}
                                  addonAfter={"%"} min={1}/>
                              </>}
                            </Col>
                            <Col span={6}>
                              <p>A partir de Quando?</p>
                              <DatePicker
                                value={boletoLateFeeStartDate}
                                onChange={(e) => setBoletoLateFeeStartDate(e)}
                                format="YYYY-MM-DD"
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row gutter={16}>
                        <Col span={8}>
                          <p>Haverá Juros?</p>
                          <Select
                            value={boletoInterestMode}
                            onChange={(e) => setBoletoInterestMode(e)}
                          >
                            <Option value="NONE">
                              Não
                            </Option>
                            <Option value="DAILY_AMOUNT">
                              Sim, Valor Fixo por Dia
                            </Option>
                            <Option value="DAILY_PERCENTAGE">
                              Sim, Porcentagem por Dia
                            </Option>
                            <Option value="MONTHLY_PERCENTAGE">
                              Sim, Porcentagem por Mês
                            </Option>
                          </Select>
                        </Col>
                        {boletoInterestMode !== "NONE" && (
                          <>
                            <Col span={8}>
                              <p>De Quanto?</p>
                              {boletoInterestMode === "DAILY_AMOUNT" && <>
                                <InputMoney
                                  value={boletoInterestValue}
                                  onChange={(e) => setBoletoInterestValue(e.target.value)}
                                  addonBefore={"R$"} />
                              </>}
                              {(boletoInterestMode === "DAILY_PERCENTAGE" || boletoInterestMode === "MONTHLY_PERCENTAGE") && <>
                                <InputNumber
                                  value={boletoInterestValue}
                                  onChange={(e) => setBoletoInterestValue(e)}
                                  addonAfter={"%"} min={1}/>
                              </>}
                            </Col>
                            <Col span={6}>
                              <p>A partir de Quando?</p>
                              <DatePicker
                                value={boletoInterestStartDate}
                                onChange={(e) => setBoletoInterestStartDate(e)}
                                format="YYYY-MM-DD"
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                    </>
                      )}
                  </>
                )}
              </Card>
            </div>
            <div className="container-right">
              <Card>
                <p className="title">Cartão</p>
                <Divider />
                <Checkbox checked={credit} onChange={handleCrediToggle}>
                  Permitir Cartão de Crédito
                </Checkbox>
                {credit && (
                  <>
                    <div>
                      <p>Máximo de parcelas</p>
                      <Select
                        placeholder="Selecione um número"
                        onChange={(e) => setMaxInstallments(e)}
                        value={maxInstallments}
                      >
                        {[...Array(12).keys()].map((num) => (
                          <Option key={num + 1} value={num + 1}>
                            x{num + 1}
                          </Option>
                        ))}
                      </Select>
                    </div>
                    <div>
                      <p>Máximo de parcelas sem taxa</p>
                      <Select
                        placeholder="Selecione um número"
                        onChange={(e) => setMaxInstallmentsTax(e)}
                        value={maxInstallmentsTax}
                      >
                        <Option key={0} value={0}>
                          Juros ao comprador
                        </Option>
                        {[...Array(12).keys()].map((num) => (
                          <Option key={num + 1} value={num + 1}>
                            x{num + 1}
                          </Option>
                        ))}
                      </Select>
                    </div>
                  </>
                )}
              </Card>
              <Card style={{ marginTop: "20px" }}>
                <p className="title">PIX</p>
                <div>
                  <Checkbox checked={pix} onChange={handlePixToggle}>
                    Permitir PIX
                  </Checkbox>
                </div>
              </Card>
            </div>
          </div>
        </Content>

        <div className="container-buttons">
          <Button
            onClick={onFinish}
            type="primary"
          >
            Criar Link de Pagamento
          </Button>
        </div>
      </Layout>
    </div>
  );
};

export default PaymentCreate;
