import {
  Breadcrumb,
  Button,
  Divider,
  Input,
  Layout,
  notification,
  Result,
  Skeleton,
  theme
} from "antd";
import { Content } from "antd/es/layout/layout";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { apiReceivableDetail } from "../../../../api/receivables";
import { formatPrice } from "../../../../utils/format";
import "./ReceivableInfo.scss";
import {EyeOutlined} from "@ant-design/icons";

const ReceivableInfo = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const pathname = window.location.pathname;
  const parts = pathname.split("/");
  const receivableID = parts[parts.length - 1];

  const [dataTransaction, setDataTransaction] = useState();
  const [loading, setLoading] = useState(false);
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");
  const navigate = useNavigate();

  const dateObject = dataTransaction && new Date(dataTransaction?.created_at);
  const dateFormated =
    dataTransaction && format(dateObject, "dd/MM/yyyy 'às' HH:mm:ss");

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/listagem-vendas")} type="primary">
              Listagem de vendas
            </Button>
          }
        />
      );
    }
  };

  const handleList = async () => {
    setLoading(true);
    try {
      const json = await apiReceivableDetail(receivableID);
      setDataTransaction(json);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setActiveError(true);
      if (error.status === 400 || error.status === 404) {
        setErrorStatus("404");
      } else if (error.status === 500) {
        setErrorStatus("500");
      } else {
        setErrorStatus("404");
      }
    }
  };

  useEffect(() => {
    handleList();
  }, []);

  return (
    <div className="ReceivableInfo">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        {errorStatus ? (
          renderError()
        ) : (
          <>
            <Breadcrumb
              style={{
                margin: "16px 0",
              }}
            >
              {loading ? <Skeleton active paragraph={{ rows: 0 }} /> :
                <h2 className="title-page">
                  {formatPrice(dataTransaction?.amount)}
                </h2>
              }
              <Divider />
              {loading ? <Skeleton active paragraph={{ rows: 0 }} /> :
                <>
                  <Breadcrumb.Item>
                    {formatPrice(dataTransaction?.amount)}
                  </Breadcrumb.Item>
                  <Breadcrumb.Item>RECEBÍVEL #{dataTransaction?.id}</Breadcrumb.Item>
                </>
              }
            </Breadcrumb>
            <Content
              style={{
                padding: 24,
                margin: 0,
                minHeight: 280,
                background: colorBgContainer,
                borderRadius: borderRadiusLG,
              }}
            >
              {loading ? <Skeleton active paragraph={{ rows: 1 }} /> :
                <div className="container-subtitle">
                  <h3 className="subtitle-page">Informações do recebível</h3>
                  <span
                    className={`badge ${dataTransaction?.status === "paid"
                      ? "badge badge-success"
                      : dataTransaction?.status === "deleted"
                        ? "badge-error"
                          : dataTransaction?.status === "scheduled"
                            ? "yellow"
                              : dataTransaction?.status === "pending"
                                ? "orange"
                                  : dataTransaction?.status === "created"
                                    ? "orange"
                                      : dataTransaction?.status === "refunded"
                                        ? "badge-error"
                                        : "black"
                      }`}
                  >
                    {dataTransaction?.status === "paid"
                      ? "Pago"
                      : dataTransaction?.status === "deleted"
                        ? "Deletado"
                        : dataTransaction?.status === "scheduled"
                          ? "Agendado"
                          : dataTransaction?.status === "created"
                            ? "Pendente"
                            : dataTransaction?.status === "pending"
                              ? "Pendente"
                              : dataTransaction?.status === "refunded"
                                ? "Estornado"
                                : "Desconhecido"}
                  </span>
                </div>
              }
              <Divider />
              {loading ? <Skeleton active paragraph={{ rows: 3 }} /> :
                <div className="container-inputs">
                  <div className="container-left">
                    <div>
                      <p>Data/hora da criação</p>
                      <Input disabled value={dateFormated} />
                    </div>
                    <div>
                      <p>Parcela</p>
                      <Input
                        disabled
                        value={dataTransaction?.installment ? dataTransaction?.installment : "À vista"}
                      />
                    </div>
                    <div>
                      <p>Status</p>
                      <Input
                        disabled
                        value={dataTransaction?.status === "paid"
                          ? "Pago"
                          : dataTransaction?.status === "deleted"
                            ? "Deletado"
                            : dataTransaction?.status === "scheduled"
                              ? "Agendado"
                              : dataTransaction?.status === "pending"
                                ? "Pendente"
                                : dataTransaction?.status === "refunded"
                                  ? "Estornado"
                                  : "Desconhecido"}
                      />
                    </div>
                  </div>
                  <div className="container-right">
                    <div>
                      <p>Data prevista para liquidação</p>
                      <Input
                        disabled
                        value={dataTransaction?.expected_on ? format(dataTransaction?.expected_on, "dd/MM/yyyy") : "Sem informação"}
                      />
                    </div>
                    <div>
                      <p>Valor líquido</p>
                      <Input
                        disabled
                        value={
                          formatPrice(dataTransaction?.amount)
                        }
                      />
                    </div>
                  </div>
                </div>
              }
            </Content>
            {!loading && (
              <>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: dataTransaction?.customer ? 280 : 40,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">Informações do titular</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <div>
                        <p>Originador</p>
                        <div className='input-container'>
                          <Input disabled value={dataTransaction?.receiver_account?.display_name} />
                          <EyeOutlined
                            onClick={() => window.open(`/lojista/detalhes/${dataTransaction.receiver_account.id}`, "_blank")}
                            className='eye-icon'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container-right">
                      <div>
                        <p>Conta Base</p>
                        <div className='input-container'>
                          <Input disabled value={dataTransaction?.acquirer_account?.display_name} />
                          <EyeOutlined
                            onClick={() => window.open(`/lojista/detalhes/${dataTransaction.acquirer_account.id}`, "_blank")}
                            className='eye-icon'
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </Content>

                <Content
                  style={{
                    padding: 24,
                    margin: "30px 0 0 0",
                    minHeight: 280,
                    background: colorBgContainer,
                    borderRadius: borderRadiusLG,
                  }}
                >
                  <div className="container-subtitle">
                    <h3 className="subtitle-page">Informações do autorizador</h3>
                  </div>
                  <Divider />
                  <div className="container-inputs">
                    <div className="container-left">
                      <div>
                        <p>Transação Originadora</p>
                        <div className='input-container'>
                          <Input disabled value={dataTransaction?.transaction} />
                          <EyeOutlined
                            onClick={() => window.open(`/transacao/${dataTransaction.transaction}`, "_blank")}
                            className='eye-icon'
                          />
                        </div>
                      </div>
                    </div>
                    <div className="container-right">
                    </div>
                  </div>
                </Content>
              </>
            )}
          </>
        )}
      </Layout>
    </div>
  );
};

export default ReceivableInfo;
