import { InfoCircleOutlined } from "@ant-design/icons";
import {
  Breadcrumb,
  Button,
  Divider,
  Empty,
  Input,
  Layout,
  Result,
  Skeleton,
  Table,
  notification,
  theme
} from "antd";
import React, {useEffect, useRef, useState} from "react";
import { Link, useNavigate } from "react-router-dom";
import { apiReceivablesList } from "../../../../api/receivables";
import { useIsMobileHook } from "../../../../utils/WindowHooks";
import { formatDate, formatPrice } from "../../../../utils/format";
import SellerDropdown from "../../../common/SellerDropdown/SellerDropdown";
import "./ReceivableListing.scss";

const { Content } = Layout;

const ReceivableListing = () => {
  const [dataSales, setDataSale] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const isMobile = useIsMobileHook(1600);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(dataSales?.results);
  const [pagination, setPagination] = useState({
    current: 1,
    total: 0,
    pageSize: 10,
  });
  const navigate = useNavigate();
  const [activeError, setActiveError] = useState(false);
  const [errorStatus, setErrorStatus] = useState("");

  const handleRowClick = (record) => {
    const id = record.id;
    const newExpandedRowKeys = [...expandedRowKeys];
    const index = newExpandedRowKeys.indexOf(id);
    if (index > -1) {
      newExpandedRowKeys.splice(index, 1);
    } else {
      newExpandedRowKeys.push(id);
    }
    setExpandedRowKeys(newExpandedRowKeys);
  };

  const hasPageBeenRendered = useRef(false);
  useEffect(() => {
    if (hasPageBeenRendered.current) {
      const delayDebounceFn = setTimeout(() => {
        fetchData({
          page: pagination.current,
          search: searchText,
        });
      }, 1500)

      return () => clearTimeout(delayDebounceFn)
    }
    hasPageBeenRendered.current = true;
  }, [searchText])

  const handleSearch = (value) => {
    fetchData({
      page: pagination.current,
      search: searchText,
    });
  };

  const fetchData = async (params) => {
    setIsFetching(true);
    try {
      const filteredParams = Object.fromEntries(
        Object.entries(params).filter(([_, value]) => value !== undefined && value !== null && value !== '')
      );
      const json = await apiReceivablesList(filteredParams);
      setDataSale(json);
      setFilteredData(json.results);
      setPagination((prevPagination) => ({
        ...prevPagination,
        total: json.count,
        current: params.page,
      }));
    } catch (error) {
      setActiveError(true);
      setErrorStatus(error.status);
      notification.error({
        message: "Erro",
        description: "Ocorreu um erro ao buscar os recebiveis.",
        placement: "topRight",
      });
    } finally {
      setIsFetching(false);
    }
  };

  const onChangePage = (pagination) => {
    fetchData({
      page: pagination.current,
      pageSize: pagination.pageSize,
      search: searchText || undefined,
    });
  };

  useEffect(() => {
    fetchData({
      page: pagination.current,
      pageSize: pagination.pageSize,
      search: searchText || undefined,
    });
  }, []);


  const statusMap = {
    paid: <span className="badge badge-success">Confirmada</span>,
    deleted: <span className="badge badge-error">Deletado</span>,
    scheduled: <span className="badge yellow">Agendado</span>,
    pending: <span className="badge orange">Pendente</span>,
    created: <span className="badge orange">Pendente</span>,
    refunded: <span className="badge badge-error">Estornado</span>,
  };

  const renderStatus = (status) => {
    return statusMap[status] || <span className="badge black">Indefinido</span>;
  };

  const columns = [
    {
      title: "Data Prevista",
      dataIndex: "expected_on",
      key: "expected_on",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatDate(confirmed) }}
        />
      ),
    },
    {
      title: "Transação",
      dataIndex: "transaction",
      key: "transaction",
      onCell: () => ({
        style: { cursor: "pointer" },
      }),
      render: (confirme) => (
        <Link to={`/transacao/${confirme}`}>
          {confirme}
        </Link>
      ),
      responsive: ["sm"],
    },
    {
      title: "Originador",
      dataIndex: ["receiver_account", "display_name"],
      key: "receiver_account__display_name",
      responsive: ["xxl"],
    },
    {
      title: "Conta Base",
      dataIndex: ["acquirer_account", "display_name"],
      key: "acquirer_account__display_name",
      responsive: ["xxl"],
    },
    {
      title: "Tipo",
      dataIndex: "has_split_linked",
      key: "has_split_linked",
      responsive: ["sm"],
      render: (has_split) => (
        <p
          style={{ textTransform: "capitalize" }}
        >
          {has_split ? "Comissão" : "Venda"}
        </p>
      ),
    },
    {
      title: "Data da Criação",
      dataIndex: "created_at",
      key: "created_at",
      responsive: ["sm"],
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatDate(confirmed) }}
        />
      ),
    },
    {
      title: "Valor",
      dataIndex: "amount",
      key: "amount",
      render: (confirmed) => (
        <p
          style={{ textTransform: "capitalize" }}
          dangerouslySetInnerHTML={{ __html: formatPrice(confirmed) }}
        />
      ),
      responsive: ["lg"],
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: renderStatus,
    },
    {
      title: "",
      dataIndex: "",
      render: (confirme) => (
        <Link to={`/recebivel/${confirme.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      ),
      key: "",
      responsive: ["lg"],
    },
  ];

  const expandedRowRender = (record) => (
    <div className="expandid">
      <span>
       Data Prevista:{" "}
        <p
          dangerouslySetInnerHTML={{ __html: formatDate(record.expected_on) }}
        />
      </span>
      <span>
        Originador:{" "}
        <p>{record.receiver_account ? record.receiver_account.display_name : ""}</p>
      </span>
      <span>
        Conta Base:{" "}
        <p>{record.acquirer_account ? record.acquirer_account.display_name : ""}</p>
      </span>
      <span>
        Tipo:{" "}
        <p>{record.has_split_linked ? "Comissão" : "Venda"}</p>
      </span>
      <span>
        Valor bruto:{" "}
        <p dangerouslySetInnerHTML={{ __html: formatPrice(record.amount) }} />
      </span>
      <span>
        Status:{" "}
        {renderStatus(record.status)}
      </span>
      <span style={{ marginTop: "10px" }}>
        Informação:{" "}
        <Link to={`/recebivel/${record.id}`}>
          <InfoCircleOutlined style={{ cursor: "pointer", height: "100%" }} />
        </Link>
      </span>
    </div>
  );
  const locale = {
    emptyText: <Empty description="Sem dados" />,
  };

  const renderError = (status) => {
    if (activeError) {
      return (
        <Result
          status={status === "500" ? "500" : "404"}
          title={status}
          subTitle={
            status === "500"
              ? "Ocorreu um erro no servidor, tente novamente mais tarde."
              : "Ocorreu um erro, tente novamente mais tarde."
          }
          extra={
            <Button onClick={() => navigate("/")} type="primary">
              Dashboard
            </Button>
          }
        />
      );
    }
  };

  return (
    <div className="ReceivableListing">
      <Layout
        style={{
          padding: "1.25rem",
        }}
      >
        <Breadcrumb
          style={{
            margin: "16px 0",
          }}
        >
          <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }}>
            <h2 className="title-page">Agenda de recebimento</h2>
            <SellerDropdown />
          </div>
          <Divider />
          <Breadcrumb.Item>Minhas vendas</Breadcrumb.Item>
          <Breadcrumb.Item>Agenda de recebimento</Breadcrumb.Item>
        </Breadcrumb>
        <Content
          style={{
            padding: 24,
            margin: 0,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <h3 className="subtitle-page">Listagem de recebíveis</h3>
          <Divider />
          <Input.Search
            placeholder="Pesquisar"
            allowClear
            enterButton
            onSearch={handleSearch}
            onChange={(e) => setSearchText(e.target.value)}
            style={{ marginBottom: 16, maxWidth: '200px' }}
          />
          {isFetching ? (
            <Skeleton active />
          ) : activeError ? (
            renderError(errorStatus)
          ) : (
            <Table
              columns={columns}
              locale={locale}
              expandable={{
                expandedRowRender: isMobile ? expandedRowRender : undefined,
                expandedRowKeys: expandedRowKeys,
                onExpand: (expanded, record) => handleRowClick(record),
                responsive: ["xl"],
              }}
              dataSource={
                filteredData &&
                filteredData.map((item) => ({ ...item, key: item.id }))
              }
              pagination={{
                current: pagination.current,
                total: pagination.total,
                pageSize: pagination.pageSize,
                showSizeChanger: false,
              }}
              bordered
              onChange={onChangePage}
            />
          )}

        </Content>
      </Layout>
    </div >
  );
};

export default ReceivableListing;